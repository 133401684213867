const firebaseConfig = {
  apiKey: "AIzaSyDpui-xzNG4GbOJfzIYiuA5rjF0jxhwJvA",
  authDomain: "navi-31b50.firebaseapp.com",
  projectId: "navi-31b50",
  storageBucket: "navi-31b50.appspot.com",
  messagingSenderId: "844326745356",
  appId: "1:844326745356:web:6424dac58d7953120a4529",
  measurementId: "G-V201WK6NQW"
}

export default firebaseConfig